var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "store-name", required: true },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("e-search-sku", {
                    attrs: { id: "product-name", required: false },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "sale-id",
                      type: "text",
                      label: _vm.$t("Id da venda"),
                      placeholder: _vm.$t("Id da venda"),
                    },
                    model: {
                      value: _vm.filters.saleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "saleId", $$v)
                      },
                      expression: "filters.saleId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "status",
                      type: "vue-select",
                      label: _vm.$t("Status"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.stockReservationStatusOptions(),
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: "date-range-picker",
                      label: _vm.$t("Período"),
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockReservations",
            staticClass: "mb-1 bordered",
            attrs: {
              responsive: "",
              "show-empty": "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_RESERVATION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.stockReservations,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(skuName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.sku.id,
                        "product-id": item.sku.productId,
                        "product-name": item.sku.name,
                        ean: item.sku.ean,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: row.item.status,
                        options: _vm.stockReservationStatusOptions(),
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(saleId)",
                fn: function (row) {
                  return [
                    row.item.saleId || row.item.eventId
                      ? _c("e-router-link", {
                          attrs: {
                            to: _vm.getRouterLink(row.item),
                            title: row.item.saleId
                              ? _vm.$t("Venda")
                              : _vm.$t("Evento"),
                            text: row.item.saleId
                              ? String(row.item.saleId)
                              : row.item.eventId +
                                " (" +
                                _vm.$t("Evento") +
                                ")",
                            value: row.item.saleId || row.item.eventId,
                          },
                        })
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }