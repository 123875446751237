<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="store-name"
            v-model="filters.storeId"
            :required="true"
          />
        </b-col>
        <b-col md="5">
          <e-search-sku
            id="product-name"
            v-model="filters.skuId"
            :required="false"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="sale-id"
            v-model="filters.saleId"
            type="text"
            :label="$t('Id da venda')"
            :placeholder="$t('Id da venda')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="status"
            v-model="filters.status"
            type="vue-select"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            :options="stockReservationStatusOptions()"
          />
        </b-col>
        <b-col md="5">
          <FormulateInput
            v-model="filters.period"
            type="date-range-picker"
            :label="$t('Período')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="tablestockReservations"
        responsive
        show-empty
        striped
        class="mb-1 bordered"
        :empty-text="getEmptyTableMessage($tc('STOCK_RESERVATION.NAME'), 'female')"
        :fields="fields"
        :items="stockReservations"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(skuName)="{ item }">
          <e-product-router-link
            :sku-id="item.sku.id"
            :product-id="item.sku.productId"
            :product-name="item.sku.name"
            :ean="item.sku.ean"
          />
        </template>
        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="stockReservationStatusOptions()"
          />
        </template>
        <template #cell(saleId)="row">
          <e-router-link
            v-if="row.item.saleId || row.item.eventId"
            :to="getRouterLink(row.item)"
            :title="row.item.saleId ? $t('Venda') : $t('Evento')"
            :text="
              row.item.saleId ? String(row.item.saleId) : `${row.item.eventId} (${$t('Evento')})`
            "
            :value="row.item.saleId || row.item.eventId"
          />
          <span v-else>-</span>
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
  ESearchSku,
  EStoreCombo,
  EFilters,
  ERouterLink,
  EProductRouterLink,
} from '@/views/components'
import { formulateValidation, stockDomain } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EFilters,
    ESearchSku,
    ERouterLink,
    EStoreCombo,
    EProductRouterLink,
  },

  mixins: [formulateValidation, stockDomain],

  data() {
    return {
      fetching: false,
      saving: false,
      skus: [],
    }
  },

  computed: {
    ...mapGetters('app', ['storeOptions']),
    ...mapState('pages/stock/stockReservation', [
      'stockReservations',
      'sorting',
      'paging',
      'filters',
    ]),

    fields() {
      return [
        {
          label: this.$t('Data/Hora'),
          key: 'createdDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Loja'),
          key: 'store.name',
          thStyle: { width: '250px' },
          formatter: (val, key, item) => this.$options.filters.storeName(item.store),
        },
        {
          label: this.$t('Produto'),
          key: 'skuName',
          sortKey: 'sku.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => (value ? this.$t('Ativa') : this.$t('Finalizada')),
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Venda'),
          key: 'saleId',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || '-',
        },
      ]
    },

    today() {
      return new Date()
    },
  },

  mounted() {
    if (this.$route.query?.storeId || this.$route.query?.skuId) {
      this.setFilters({
        storeId: this.$route.query?.storeId?.toString(),
        skuId: this.$route.query?.skuId?.toString(),
      })
    }
    this.getData()
  },

  methods: {
    ...mapActions('pages/stock/stockReservation/stockReservationMaintain', {
      stCleanStateStockReservationMaintain: 'cleanState',
    }),
    ...mapActions('pages/stock/stockReservation', [
      'fetchStockReservations',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'setFilters',
    ]),

    getRouterLink(item) {
      return {
        name: item.saleId ? 'order-read-only' : 'event-read-only',
        params: { id: item.saleId ?? item.eventId },
      }
    },

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStockReservations()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreate() {
      this.stCleanStateStockReservationMaintain()
      this.$router.push({ name: 'stock-movement-add' })
    },

    async showUpdateBrandSidebar(row) {
      this.$router.push({ name: 'stock-movement-maintain', params: { id: row.id } })
    },
  },
}
</script>

<style lang="scss" scoped></style>
